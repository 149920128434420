export const servicesData = [
    {
      title: "Local Freight",
      text: `
          Our local freight services are designed to seamlessly connect businesses with their local markets. From sameday deliveries to scheduled shipments, we ensure that your goods reach their destination on time, every time. Our dedicated team and advanced tracking systems guarantee the security and timely delivery of your cargo.`,
      image: process.env.PUBLIC_URL + "/images/local-freight.jpg",
    },
    {
      title: "International Freight",
      text: `
          Expand your global reach with our international freight services. Whether by sea, air, or land, we offer a comprehensive range of options to suit your specific shipping needs. Our experienced team will handle all the necessary documentation, customs clearance, and logistics, providing you with a hasslefree international shipping experience.`,
      image: process.env.PUBLIC_URL + "/images/cargo.jpg",
    },
    {
      title: "Charter Flight",
      text: `
          When time is of the essence, our charter flight services offer a swift and efficient solution. Enjoy the flexibility of a personalized flight schedule to transport your goods promptly and securely. Our dedicated charter services ensure that your cargo is in safe hands from takeoff to delivery, providing you with peace of mind.`,
      image: process.env.PUBLIC_URL + "/images/plane.jpg",
    },
    {
      title: "Logistics",
      text: `
          Efficient logistics are the backbone of successful businesses. At Blackwings, we excel in providing end-to-end logistics solutions that streamline your supply chain. From inventory management to distribution and fulfillment, our logistics services are designed to optimize your operations, reduce costs, and enhance overall efficiency.`,
      image: process.env.PUBLIC_URL + "/images/logistic2.jpg",
    },
    {
      title: "Handling",
      text: `
          Our handling services ensure the careful and secure treatment of your valuable cargo. From delicate items to oversized shipments, our trained professionals use stateoftheart equipment to handle your goods with precision. Trust us to safeguard your cargo throughout the handling process, from arrival to departure.`,
      image: process.env.PUBLIC_URL + "/images/handling2.jpg",
    },
];


export const navigationLinksData = [
    { text: "Home", path: "/" },
    { text: "Services", path: "/", subLinks: servicesData},
    { text: "About Us", path: "/" },
    { text: "Blog", path: "/" },
    { text: "Contact", path: "/" },
  ];