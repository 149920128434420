import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { navigationLinksData, servicesData } from "../../constances/constances";
import { Link } from "react-router-dom";
import SocialMedia from "../SocialMedia";

export default function Footer() {
  return (
    <footer className="bg-darkblue text-gray-400 px-[5%] py-5">
      <ContactInfo />
      <div className="md:flex mt-9">
        <div className="md:flex flex-1">
          <div className="flex-1">
            <h3 className="text-2xl font-bold mb-3 text-white">Our Mission</h3>
            <p>
              At Black Wings, our mission is to provide reliable and efficient
              cargo transportation services to businesses and individuals around
              South Sudan and around the world. We strive to be the premier
              choice for all of your shipping needs, and work tirelessly to
              ensure that your goods are delivered safely, securely, and on time
            </p>
            <div className="md:mb-0 mb-10 mt-7">
              <h3 className="text-2xl">Follow us</h3>
              <SocialMedia cName="flex mt-5" size="text-4xl" />
            </div>
          </div>
          <div className="md:ml-32">
            <h3 className="text-2xl font-bold mb-2 text-white">Quick Links</h3>
            <ul className="">
              {navigationLinksData.map((link) => (
                <li className="">
                  <Link className="py-2 block" to={link.path}>
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="md:flex">
          <div className="md:mx-20 md:my-0 my-5">
            <h3 className="text-2xl font-bold mb-2 text-white">Services</h3>
            <ul className="">
              {servicesData.map((link) => (
                <li className="">
                  <Link
                    className="py-2 flex items-center hover:text-white"
                    to={link.path}
                  >
                    <div className="header-arrow-small bg-darkorange w-[10px] h-4"></div>
                    <div className="header-arrow-small bg-darkorange w-[8px] h-4 mr-2"></div>
                    {link.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="">
            <h3 className="text-2xl font-bold mb-2 text-white">Quick Links</h3>
            <ul className="">
              {navigationLinksData.map((link) => (
                <li className="mb-3">
                  <Link
                    className="flex items-center hover:text-white"
                    to={link.path}
                  >
                    <div className="header-arrow-small bg-darkorange w-[10px] h-4"></div>
                    <div className="header-arrow-small bg-darkorange w-[8px] h-4 mr-2"></div>
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

function ContactInfo() {
  return (
    <div className="md:bg-darkorange md:flex justify-between text-white md:p-6 mb-16 rounded-lg">
      <div className="flex flex-1 items-center">
        <div className="md:text-5xl text-2xl text-darkorange bg-white p-2 rounded">
          <FaPhoneAlt />
        </div>
        <div className="flex-1 ml-3">
          <div className="md:block hidden text-2xl font-bold">Call Us</div>{" "}
          +211922055000 | +211912366566
        </div>
      </div>
      <div className="flex flex-1 items-center md:my-0 my-5">
        <div className="md:text-5xl text-2xl text-darkorange bg-white p-2 rounded">
          <FiMail />
        </div>
        <div className="flex-1 ml-4">
          <div className="md:block hidden text-2xl font-bold">Mail Us</div>
          info@blackwings-ss.com
        </div>
      </div>
      <div className="flex flex-1 items-center">
        <div className="md:text-5xl text-2xl text-darkorange bg-white p-2 rounded">
          <FaMapMarkerAlt />
        </div>
        <div className="flex-1 ml-4">
          <div className="md:block hidden text-2xl font-bold">Visit Us</div>
          office No. 204 Quality Plaza, Opp Former Gpoc
        </div>
      </div>
    </div>
  );
}
