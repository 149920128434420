import { Link } from "react-router-dom";
import { navigationLinksData } from "../../constances/constances";
import Logo from "./Logo";
import { FaPhoneAlt } from "react-icons/fa";
import { FiBarChart2, FiChevronDown, FiMail } from "react-icons/fi";
import { motion } from "framer-motion";
import { useState } from "react";
import MobileNavbar from "./MobileNavbar";

export default function Navbar({ toggleModule }) {
  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = () => setOpenMenu(!openMenu);
  return (
    <nav className="w-full sticky inset-0 z-40">
      <div className="md:flex hidden items-center justify-between bg-darkorange text-white px-[5%] py-3">
        <div className="flex">
          <div className="flex items-center mr-5">
            <FaPhoneAlt className="mt-1 mr-2" />
            +211922055000
          </div>
          <div className="flex items-center">
            <FiMail className="mt-1 mr-2" />
            +211922055000
          </div>
        </div>
        <div className="flex items-center">
          <div className="">Login</div>
          <div className="mx-2">|</div>
          <span>Signup</span>
        </div>
      </div>
      <div className="flex items-center justify-between bg-white px-[5%]">
        <Logo />
        <div className="md:block hidden">
          <ul className="flex uppercase font-bold">
            {navigationLinksData.map((link) => (
              <li className="" key={link.text}>
                {!link.subLinks ? (
                  <Link className="block p-5" to={link.path}>
                    {link.text}
                  </Link>
                ) : (
                  <SubLink link={link} />
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="">
          <button
            className="md:block hidden bg-darkorange uppercase text-white px-6 py-2"
            onClick={toggleModule}
          >
            Our Schedule
          </button>
          <div
            className="md:hidden flex text-2xl -rotate-90"
            onClick={toggleMenu}
          >
            <FiBarChart2 />
          </div>
        </div>
      </div>
      <MobileNavbar openMenu={openMenu} toggleMenu={toggleMenu} />
    </nav>
  );
}

function SubLink({ link }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className="flex items-center p-5 relative"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {link.text}
      <FiChevronDown className="text-2xl" />
      {isOpen && (
        <motion.div
          initial={{ scaleY: 0 }}
          animate={{ scaleY: 1 }}
          transition={{ duration: 0.2 }}
          className="w-[200px] origin-top absolute left-0 top-full font-normal bg-gray-100 py-2"
        >
          {link.subLinks.map((item) => (
            <div className="px-4 py-2">{item.title}</div>
          ))}
        </motion.div>
      )}
    </div>
  );
}
