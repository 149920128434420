import { FaFacebook, FaInstagram, FaTwitter, FaWhatsapp } from "react-icons/fa";

export default function SocialMedia({
  size = "text-xl",
  cName = "md:flex hidden",
  iconCName = "p-2 borderd border-darkorange text-darkorange bg-white rounded-xl md:mr-8 mr-3",
}) {
  return (
    <div className={`${cName} ${size} items-center`}>
      <span className={`${iconCName}`}>
        <FaFacebook />
      </span>
      <span className={`${iconCName}`}>
        <FaTwitter />
      </span>
      <span className={`${iconCName}`}>
        <FaInstagram />
      </span>
      <span className={`${iconCName}`}>
        <FaWhatsapp />
      </span>
    </div>
  );
}
