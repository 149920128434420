import { Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Appbar from "./components/navbar";
import Footer from "./components/footer";
import { Suspense, lazy, useState } from "react";
import OurSchedule from "./components/OurSchedule";

const HomePage = lazy(() => import("./pages/home"));

function App() {
  const [showModule, setShowModule] = useState(false);
  const toggleModule = () => setShowModule(!showModule);
  return (
    <Suspense fallback={<Loader />}>
      <div className="md:text-base">
        <ScrollToTop />
        <Appbar toggleModule={toggleModule} />
        {showModule && <OurSchedule toggleModule={toggleModule} />}
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
        <Footer />
      </div>
    </Suspense>
  );
}

export default App;

function Loader() {
  return (
    <div className="h-screen fixed inset-0 bg-sky-800 text-white flex items-center justify-center">
      <div className="">
        <div className="h-32 w-32 p-5">
          <img src="/images/blackwings.png" alt="" />
        </div>
        <div className="text-center mt-5">Loading...</div>
      </div>
    </div>
  );
}
