import { FiX } from "react-icons/fi";
import { motion, AnimatePresence } from "framer-motion";

export default function OurSchedule({ toggleModule }) {
  return (
    <AnimatePresence mode="wait" initial={false}>
      <div className="h-screen flex items-center justify-center fixed inset-0 z-50 bg-black/50 backdrop-blur-md">
        <motion.div
          initial={{ y: "-100%" }}
          animate={{ y: 0 }}
          exit={{ y: "100%" }}
          transition={{ duration: 0.9 }}
          className="bg-white h-[60%] md:w-[80%] w-[90%] relative"
        >
          <div
            className="absolute right-2 top-2 bg-gray-300 text-2xl p-1 rounded-full"
            onClick={toggleModule}
          >
            <FiX />
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
}
